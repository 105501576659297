import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import has from 'lodash/has';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../../redux/actions/authentication/actionCreators';

import { growl } from '../../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../../redux/actions/authorization/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';
import theme, { eyebrowMixin } from '../../styles/theme';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import format from 'date-fns/format';
import classnames from 'classnames';

import Layout from '../../layouts/backendLayout';
import LoginForm from '../../components/loginForm';
import Gravatar from '../../primespot-ui/components/gravatar';
import LoadingSpinner from '../../primespot-ui/components/loadingSpinner';

import scrollToBottom from '../../util/scrollToBottom';
import config from '../../config';

const SEND_MESSAGE = 'conversations.messages.new';

class conversationsPage extends Component {
  state = {
    socketConnected: false,
    messageListLoading: false,
    openConversations: [],
    selectedConversation: null,
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.user && this.props.token) {
      this.loadConversations();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.user && this.props.user) ||
      (!prevProps.token && this.props.token)
    ) {
      this.loadConversations();
    }
  }

  loadConversations = () => {
    if (!this.conversationsSocket) {
      this.conversationsSocket = io(`${config.backendServer}/conversations`);
    }

    this.conversationsSocket.on('connect', () => {
      this._isMounted &&
        this.setState({ messageListLoading: true, socketConnected: true });
      this.conversationsSocket.emit(
        'conversations.list',
        {
          token: this.props.getToken(),
          status: 'open',
        },
        (err, data) => {
          if (err) {
            console.error(
              'An error occurred attempting to load open conversations.'
            );
            console.error(err);
          } else {
            this._isMounted &&
              this.setState({
                openConversations: data,
                messageListLoading: false,
              });
          }
        }
      );

      this.conversationsSocket.emit(
        'conversations.watch',
        {
          token: this.props.token,
        },
        (err, res) => {
          if (err) {
            console.error(
              'An error occurred while attempting to watch conversations.'
            );
            console.error(err);
          }
        }
      );

      this.conversationsSocket.on('disconnect', () => {
        this._isMounted && this.setState({ socketConnected: false });
      });

      this.conversationsSocket.on(
        'conversations.messages.events.new',
        (conversation = {}) => {
          if (conversation) {
            const conversationIndex = this.state.openConversations.findIndex(
              oc => oc._id === conversation._id
            );

            if (conversationIndex !== -1) {
              // Conversation exists.
              this._isMounted &&
                this.setState(
                  prevState => ({
                    openConversations: prevState.openConversations.map(oc =>
                      oc._id !== conversation._id ? oc : conversation
                    ),
                  }),
                  () => {
                    const { messages } = conversation;
                    const lastMessage = messages[messages.length - 1];
                    if (
                      !lastMessage.user ||
                      lastMessage.user._id !== this.props.user._id
                    ) {
                      this.playNotificationSound();
                    }
                  }
                );
            } else {
              // New conversation.
              this._isMounted &&
                this.setState(
                  prevState => ({
                    openConversations: prevState.openConversations.concat(
                      conversation
                    ),
                  }),
                  () => {
                    const { messages } = conversation;
                    const lastMessage = messages[messages.length - 1];
                    if (
                      !lastMessage.user ||
                      !lastMessage.user._id !== this.props.user._id
                    ) {
                      this.playNotificationSound();
                    }
                  }
                );
            }

            if (
              this.state.selectedConversation &&
              this.state.selectedConversation._id &&
              conversation._id === this.state.selectedConversation._id
            ) {
              this._isMounted &&
                this.setState({ selectedConversation: conversation });
            }
          }
        }
      );

      this.conversationsSocket.on(
        'conversations.events.close',
        conversation => {
          if (!conversation) {
            return;
          }
          this._isMounted &&
            this.setState(prevState => ({
              openConversations: prevState.openConversations.map(oc =>
                oc._id === conversation._id ? conversation : oc
              ),
            }));

          if (this.state.selectedConversation._id === conversation._id) {
            this._isMounted &&
              this.setState({ selectedConversation: conversation });
          }
        }
      );
    });
  };

  playNotificationSound = () => {
    const context = new AudioContext();
    const o = context.createOscillator();
    const g = context.createGain();
    const frequency = 440.0;
    o.frequency.value = frequency;
    o.connect(g);
    g.connect(context.destination);
    o.start(0);
    g.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + 1);
  };

  onConversationSelect = conversation => {
    this.setState({ selectedConversation: conversation });
  };

  onCloseConversation = conversation => {
    this.conversationsSocket.emit(
      'conversations.close',
      {
        token: this.props.token,
        conversation,
      },
      (err, res) => {
        if (err) {
          console.error(err);
        }
      }
    );
  };

  sendMessage = (conversation, message) => {
    // Send message that is part of a conversation.
    if (!conversation || !message) {
      return;
    }

    const messageObject = {
      token: this.props.token,
      conversation: conversation._id,
      message,
    };

    this.conversationsSocket.emit(SEND_MESSAGE, messageObject, (err, res) => {
      if (err) {
        console.error(err);
      } else {
      }
    });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Layout
          miniSidebar
          noPadding
          location={this.props.location}
          user={this.props.user}
          login={this.props.login}
          getEmailToken={this.props.getEmailToken}
          logout={this.props.logout}
          growl={this.props.growl}
        >
          <Wrapper className="conversations-page__main-wrapper">
            {!this.props.user ||
            (this.props.user &&
              !['sales', 'admin', 'super admin'].includes(
                this.props.user.role
              )) ? (
              <LoginForm />
            ) : (
              <Fragment>
                <div
                  className={`conversations-page__connecting-message ${
                    this.state.socketConnected ? '' : 'show'
                  }`}
                >
                  Connecting to server. Please wait.
                </div>
                <ThreeColumn>
                  <MessageListColumn
                    loading={this.state.messageListLoading}
                    openConversations={this.state.openConversations}
                    user={this.props.user}
                    onConversationSelect={this.onConversationSelect}
                  />
                  <MessageColumn
                    user={this.props.user}
                    selectedConversation={this.state.selectedConversation}
                    onSendMessage={this.sendMessage}
                  />
                  <ContactMetaColumn
                    selectedConversation={this.state.selectedConversation}
                    user={this.props.user}
                    onCloseConversation={this.onCloseConversation}
                  />
                </ThreeColumn>
              </Fragment>
            )}
          </Wrapper>
        </Layout>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  position: relative;

  .conversations-page__connecting-message {
    width: 100%;
    height: 50px;
    position: absolute;
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .7s ease;
    top: -50px;
    left: 0;
    right: 0;
    opacity: 0;
  }

  .conversations-page__connecting-message.show {
    top: 0;
    opacity: 1;
  }
`;

const MessageListColumn = ({
  loading,
  openConversations,
  user,
  onConversationSelect,
}) => {
  function getMessageName(participants = []) {
    participants = participants.filter(p => !p.user || p.user._id !== user._id);
    let displayName = null;
    if (participants.length > 0) {
      if (participants[0].name) {
        displayName = participants[0].name;
      }
    }

    displayName = displayName ? displayName : 'Visitor';

    return displayName;
  }

  function getMessageEmail(conversation) {
    const { participants = [] } = conversation;

    const filteredParticipants = participants.filter(
      p => !p.user || p.user._id !== user._id
    );
    let email = null;
    if (filteredParticipants.length > 0) {
      if (filteredParticipants[0].email) {
        email = filteredParticipants[0].email;
      }
    }

    if (email) {
      return email;
    }

    const emailNameMapper1 = ['jon', 'jane', 'bob', 'beth', 'sam', 'sally'];

    const emailNameMapper2 = [
      'andrews',
      'adams',
      'manning',
      'grayson',
      'davidson',
      'matthews',
    ];

    const fakeEmail = `${
      emailNameMapper1[Math.floor(Math.random() * emailNameMapper1.length)]
    }.${
      emailNameMapper2[Math.floor(Math.random() * emailNameMapper2.length)]
    }@fakeemail.com`;

    return fakeEmail;
  }

  return (
    <MessageListColumnWrapper className="conversations-page__message-list__wrapper">
      <div className="conversations-page__message-list__header">Messages</div>
      {loading && <LoadingSpinner style={{ margin: '24px auto' }} />}
      {!loading && (
        <div className="conversations-page__message-list__messages">
          {openConversations.length === 0 && (
            <div style={{ padding: '12px', marginTop: '24px' }}>
              No open conversations
            </div>
          )}
          {openConversations
            .sort((a, b) =>
              a.messages[a.messages.length - 1].createdAt <
              b.messages[b.messages.length - 1].createdAt
                ? 1
                : -1
            )
            .map(oc => (
              <div
                key={oc._id}
                className={`conversations-page__message-list__message${
                  oc.status === 'closed' ? ' closed' : ''
                }`}
                onClick={() => {
                  onConversationSelect(oc);
                }}
              >
                <div className="conversations-page__message-list__message__type">
                  {oc.type}
                </div>
                <div className="conversations-page__message-list__message__name">
                  {
                    <Gravatar
                      size="24"
                      email={getMessageEmail(oc)}
                      className="gravatar-image"
                    />
                  }
                  {getMessageName(oc.participants)}
                </div>
                <div className="conversations-page__message-list__message__recent-message">
                  {oc.messages &&
                    oc.messages[oc.messages.length - 1].message.substring(
                      0,
                      24
                    )}
                </div>
                {oc.status === 'open' ? (
                  <div className="conversations-page__message-list__message__timestamp">
                    {`${distanceInWordsStrict(
                      new Date(),
                      oc.messages[oc.messages.length - 1].createdAt,
                      ''
                    )} ago`}
                  </div>
                ) : (
                  <div className="conversations-page__message-list__message__closed-notice">
                    Conversation closed
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </MessageListColumnWrapper>
  );
};

const MessageListColumnWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  min-width: 250px;
  border-right: 1px solid ${({ theme }) => theme.primary.colors.gray.lightest};

  .conversations-page__message-list__header {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};
  }

  .conversations-page__message-list__messages {
    flex-shrink: 0;
    overflow-y: auto;
  }

  .conversations-page__message-list__message {
    padding: 12px;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};
    cursor: pointer;
  }

  .conversations-page__message-list__message.closed {
    opacity: .5;
  }

  .conversations-page__message-list__message__type {
    ${eyebrowMixin};
    margin-bottom: 6px;
  }

  .conversations-page__message-list__message__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    .gravatar-image {
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .conversations-page__message-list__message__recent-message {
    margin-top: 12px;
    color: ${({ theme }) => theme.primary.colors.gray.darkest};
    font-size: 90%;
  }

  .conversations-page__message-list__message__timestamp {
    color: ${({ theme }) => theme.primary.colors.gray.darker};
    font-size: 70%;
    text-align: right;
    margin-top: 12px;
  }

  .conversations-page__message-list__message__closed-notice {
    color: white;
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    margin-top: 12px;
  }
`;

class MessageColumn extends Component {
  constructor(props) {
    super(props);

    this.messages = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.selectedConversation) {
      return;
    }

    if (!prevProps.selectedConversation && this.props.selectedConversation) {
      scrollToBottom(this.messages.current);
    }

    // if (
    //   prevProps.selectedConversation &&
    //   prevProps.selectedConversation.messages &&
    //   this.props.selectedConversation &&
    //   this.props.selectedConversation.messages &&
    //   prevProps.selectedConversation.messages.length !==
    //     this.props.selectedConversation.messages.length
    // ) {
    //   scrollToBottom(this.messages.current);
    // }

    if (
      has(prevProps, 'selectedConversation.messages') &&
      has(this.props, 'selectedConversation.messages') &&
      prevProps.selectedConversation.messages.length !==
        this.props.selectedConversation.messages.length
    ) {
      scrollToBottom(this.messages.current);
    }
  }

  getConversationName = participants => {
    const { user } = this.props;
    if (!participants) {
      return 'No conversation selected';
    }

    participants = participants.filter(p => !p.user || p.user !== user._id);
    let displayName = null;
    if (participants.length > 0) {
      if (participants[0].name) {
        displayName = participants[0].name;
      } else if (participants[0].firstName || participants[0].lastName) {
        displayName = `${participants[0].firstName + ''}${
          participants[0].lastName
        }`;
      }
    }

    displayName = displayName ? displayName : 'Visitor';

    return displayName;
  };

  getMessageSender = message => {
    if (!message) {
      return;
    }

    let name = '';
    if (message.user) {
      if (message.user.firstName || message.user.lastName) {
        name = `${message.user.firstName && message.user.firstName + ' '}${
          message.user.lastName
        }`;
      } else if (message.user.email) {
        name = message.user.email;
      } else {
        name = 'User';
      }
    } else if (message.contact) {
      if (message.contact.name) {
        name = message.contact.name;
      } else if (message.contact.email) {
        name = message.contact.email;
      } else {
        name = 'Visitor';
      }
    }

    return name;
  };

  handleSendMessage = () => {
    const input = document.querySelector(
      '#conversations-page__messages__body__controls__input'
    );
    this.props.onSendMessage(this.props.selectedConversation, input.value);
    input.value = '';
  };

  render() {
    const { user, selectedConversation } = this.props;

    const headerClasses = classnames('conversations-page__messages__header', {
      closed: selectedConversation && selectedConversation.status === 'closed',
    });

    return (
      <MessageColumnWrapper
        className="conversations-page__messages__wrapper"
        isConversationSelected={selectedConversation}
        isConversationClosed={
          selectedConversation && selectedConversation.status === 'closed'
        }
      >
        <div className={headerClasses}>
          {selectedConversation && selectedConversation.status === 'closed' ? (
            <span>Conversation closed</span>
          ) : (
            <Fragment>
              {this.getConversationName(
                selectedConversation && selectedConversation.participants
              )}
            </Fragment>
          )}
        </div>
        <div className="conversations-page__messages__body">
          <div
            className="conversations-page__messages__body__messages"
            ref={this.messages}
          >
            {selectedConversation &&
              selectedConversation.messages &&
              selectedConversation.messages.map(message => {
                const messageClasses = classnames(
                  'conversations-page__messages__body__messages__message',
                  {
                    'from-me': message.user && message.user._id === user._id,
                  }
                );
                return (
                  <div key={message._id} className={messageClasses}>
                    <span className="conversations-page__messages__body__messages__message__text">
                      {message.message}
                    </span>
                    <span className="conversations-page__messages__body__messages__message__time">
                      {`${this.getMessageSender(message)} \u2022 ${format(
                        message.createdAt,
                        'MM/DD/YY, h:mm a'
                      )}`}
                    </span>
                  </div>
                );
              })}
          </div>
          <div className="conversations-page__messages__body__controls">
            <label>
              <span>Message</span>
              <textarea
                id="conversations-page__messages__body__controls__input"
                placeholder="Write a reply"
                onKeyDown={ev => {
                  if (ev.keyCode === 13) {
                    ev.preventDefault();
                    this.handleSendMessage();
                  }
                }}
              />
              <button
                id="conversations-page__messages__body__controls__send-button"
                onClick={() => {
                  this.handleSendMessage();
                }}
              >
                Send
              </button>
            </label>
          </div>
        </div>
      </MessageColumnWrapper>
    );
  }
}

const MessageColumnWrapper = Styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-sizing: border-radius;
  border-right: 1px solid ${({ theme }) => theme.primary.colors.gray.lightest};

  .conversations-page__messages__header {
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};
    width: 100%;

    &.closed {
      color: white;
      background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    }
  }

  .conversations-page__messages__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 12px;
    overflow: hidden;
  }

  .conversations-page__messages__body__messages {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
    overflow-y: auto;
  }

  .conversations-page__messages__body__messages__message {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 36px;
    width: 60%;
    padding: 12px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.primary.colors.gray.lightest};
    border-radius: 4px;
    justify-content: flex-start;
    align-self: flex-start;
    position: relative;
  }

  .conversations-page__messages__body__messages__message.from-me {
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.soft};
    justify-content: flex-end;
    align-self: flex-end;
  }

  .conversations-page__messages__body__messages__message__time {
    font-size: 80%;
    position: absolute;
    top: calc(100% + 6px);
    right: 6px;
    color: ${({ theme }) => theme.primary.colors.text.gray.darker};
  }

  .conversations-page__messages__body__controls {
    display: flex;
    height: 100px;
    min-height: 100px;
    width: 100%;
    box-sizing: border-box;
    opacity: ${({ isConversationSelected, isConversationClosed }) =>
      isConversationSelected && !isConversationClosed ? 1 : 0.5};
    pointer-events: ${({ isConversationSelected, isConversationClosed }) =>
      isConversationSelected && !isConversationClosed ? 'auto' : 'none'};
  }

  .conversations-page__messages__body__controls label {
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .conversations-page__messages__body__controls label span {
    height: 0;
    width: 0;
    display: none;
  }

  #conversations-page__messages__body__controls__input {
    resize: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.primary.colors.gray.lighter};
    padding: 6px;
    color: ${({ theme }) => theme.primary.colors.text.gray.darkest};
  }

  #conversations-page__messages__body__controls__send-button {
    position: absolute;
    font-size: 14px;
    bottom: 9px;
    right: 9px;
    background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    cursor: pointer;
    transition: all .4s ease;
    opacity: .3;

    &:hover {
      background-color: ${({ theme }) =>
        theme.primary.colors.lightBlue.lightest};
      border-color: ${({ theme }) => theme.primary.colors.lightBlue.lightest};
      opacity: 1;
    }
  }
`;

function ContactMetaColumn({
  selectedConversation,
  user,
  onCloseConversation,
}) {
  function getOtherParticipantName() {
    if (!selectedConversation || !selectedConversation.participants) {
      return;
    }
    const filteredParticipants = selectedConversation.participants.filter(
      p => !p.user || p.user !== user._id
    );
    let displayName = null;
    if (filteredParticipants.length > 0) {
      if (filteredParticipants[0].name) {
        displayName = filteredParticipants[0].name;
      } else if (
        filteredParticipants[0].firstName ||
        filteredParticipants[0].lastName
      ) {
        displayName = `${filteredParticipants[0].firstName + ' '}${
          filteredParticipants[0].lastName
        }`;
      }
    }

    displayName = displayName ? displayName : 'Unknown Name';

    return displayName;
  }

  function generateAvatar(displayName) {
    if (!displayName) {
      return null;
    }
    const initials = displayName
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '');
    return (
      <span className="conversations-page__visitor-meta__basic-info__avatar">
        {initials}
      </span>
    );
  }

  // function getVisitorId() {
  //   if (!selectedConversation || !selectedConversation.participants) {
  //     return;
  //   }
  //   const filteredParticipants = selectedConversation.participants.filter(
  //     p => !p.user || p.user !== user._id
  //   );

  //   if (
  //     filteredParticipants &&
  //     filteredParticipants[0] &&
  //     filteredParticipants[0].contact
  //   ) {
  //     return filteredParticipants[0].contact.visitorId;
  //   }

  //   return null;
  // }

  function getOtherParticipantPhoneNumber() {
    if (!selectedConversation || !selectedConversation.participants) {
      return;
    }
    const filteredParticipants = selectedConversation.participants.filter(
      p => !p.user || p.user !== user._id
    );
    let phoneNumber = null;
    if (filteredParticipants.length > 0) {
      if (filteredParticipants[0].contact) {
        phoneNumber = filteredParticipants[0].contact.phoneNumber;
      } else if (filteredParticipants[0].user) {
        phoneNumber = filteredParticipants[0].user.phoneNumber;
      }
    }

    phoneNumber = phoneNumber ? phoneNumber : 'Unknown';

    return phoneNumber;
  }

  function getOtherParticipantEmail() {
    if (!selectedConversation || !selectedConversation.participants) {
      return;
    }
    const filteredParticipants = selectedConversation.participants.filter(
      p => !p.user || p.user !== user._id
    );
    let email = null;
    if (filteredParticipants.length > 0) {
      if (filteredParticipants[0].contact) {
        email = filteredParticipants[0].contact.email;
      } else if (filteredParticipants[0].user) {
        email = filteredParticipants[0].user.email;
      }
    }

    email = email ? email : 'Unknown';

    return email;
  }

  const displayName = getOtherParticipantName();
  const avatar = generateAvatar(displayName);
  // const visitorId = getVisitorId();
  const phoneNumber = getOtherParticipantPhoneNumber();
  const email = getOtherParticipantEmail();

  return (
    <ContactMetaColumnWrapper className="conversations-page__visitor-meta__wrapper">
      {selectedConversation && (
        <Fragment>
          <div className="conversations-page__visitor-meta__basic-info">
            {avatar}
            {displayName}
          </div>
          <div className="conversations-page__visitor-meta__details">
            <dl>
              <dt>Name</dt>
              <dd>{displayName}</dd>
              <dt>Phone number</dt>
              <dd>{phoneNumber}</dd>
              <dt>Email</dt>
              <dd>{email}</dd>
            </dl>
          </div>
          <div className="conversations-page__visitor-meta__actions">
            {selectedConversation && selectedConversation.status === 'open' && (
              <button
                className="btn"
                onClick={() => {
                  onCloseConversation(
                    selectedConversation && selectedConversation._id
                  );
                }}
              >
                Close conversation
              </button>
            )}
          </div>
        </Fragment>
      )}
    </ContactMetaColumnWrapper>
  );
}

const ContactMetaColumnWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
  padding-bottom: 48px;
  overflow-y: auto;

  .btn {
    border: none;
    background: none;
    color: ${({ theme }) => theme.primary.colors.darkBlue.base};
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s ease;
    border-radius: 3px;
    padding: 12px 6px;

    &:hover {
      background-color: ${({ theme }) => theme.primary.colors.darkBlue.soft};
    }
  }

  .conversations-page__visitor-meta__basic-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    padding: 24px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};
  }

  .conversations-page__visitor-meta__basic-info__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.primary.colors.orange.soft};
    color: ${({ theme }) => theme.primary.colors.orange.base};
    font-size: 24px;
    margin-bottom: 12px;
  }

  .conversations-page__visitor-meta__details {
    padding: 12px;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};
  }

  .conversations-page__visitor-meta__details dl {
    margin: 0;

    dt {
      font-size: 80%;
      color: ${({ theme }) => theme.primary.colors.gray.darkest};
      margin-bottom: 3px;
    }

    dd {
      margin-left: 0;
      font-size: 90%;
      color: #333;
      margin-bottom: 12px;
    }
  }

  .conversations-page__visitor-meta__actions {
    padding: 12px;
  }
`;

const ThreeColumn = Styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(conversationsPage);
